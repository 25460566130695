<template>
    <div class="profile container">
        <div>
            <div class="ui simple grid">
                <div class="twelve wide mobile eight wide tablet eight wide computer column">
                    <h3 class="ui header">
                        Change Password
                        <div class="sub header">Change your login password here</div>
                    </h3>
                </div>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <form class="ui password form" :class="{loading: submitting}" @submit.prevent="changePassword">
                <div class="required field">
                    <label>Current Password</label>
                    <div class="ui icon input">
                        <input ref="password" :type="inputs.password.visible? 'text' : 'password'" v-model.trim="inputs.password.value" @keyup="checkCapsLock($event, inputs.password)" @focus="checkCapsLock($event, inputs.password)" @mousedown="checkCapsLock($event, inputs.password)" @blur="removeCapsLockedClass(inputs.password)" autocomplete="current-password" required/>
                        <i class="inverted black link icon" :class="{'eye': !inputs.password.visible, 'eye slash': inputs.password.visible}" @click="togglePasswordVisibility(inputs.password)"></i>
                    </div>
                    <div class="ui red warning text" v-show="inputs.password.caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                </div>

                <div class="required field">
                    <label>New Password</label>
                    <div class="ui icon input">
                        <input :type="inputs.new_password.visible? 'text' : 'password'" v-model.trim="inputs.new_password.value" @keyup="checkCapsLock($event, inputs.new_password)" @focus="checkCapsLock($event, inputs.new_password)" @mousedown="checkCapsLock($event, inputs.new_password)" @blur="removeCapsLockedClass(inputs.new_password)" autocomplete="new-password" required/>
                        <i class="inverted black link icon" :class="{'eye': !inputs.new_password.visible, 'eye slash': inputs.new_password.visible}"  @click="togglePasswordVisibility(inputs.new_password)"></i>
                    </div>
                    <div class="ui red warning text" v-show="inputs.new_password.caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                </div>

                <div class="required field">
                    <label>Confirm New Password</label>
                    <div class="ui icon input">
                        <input :type="inputs.confirm_password.visible? 'text' : 'password'" v-model.trim="inputs.confirm_password.value" @keyup="checkCapsLock($event, inputs.confirm_password)" @focus="checkCapsLock($event, inputs.confirm_password)" @mousedown="checkCapsLock($event, inputs.confirm_password)" @blur="removeCapsLockedClass(inputs.confirm_password)" autocomplete="new-password" required/>
                        <i class="inverted black link icon" :class="{'eye': !inputs.confirm_password.visible, 'eye slash': inputs.confirm_password.visible}"  @click="togglePasswordVisibility(inputs.confirm_password)"></i>
                    </div>
                    <div class="ui red warning text" v-show="inputs.confirm_password.caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                </div>

                <div class="ui hidden divider"></div>

                <div class="ui right aligned text">
                    <button type="submit" class="ui submit teal button">Update</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "change-password",
    data() {
        return {
            inputs: {
                password: {
                    visible: false,
                    caps_locked: false,
                    value: ""
                },
                new_password: {
                    visible: false,
                    caps_locked: false,
                    value: ""
                },
                confirm_password: {
                    visible: false,
                    caps_locked: false,
                    value: ""
                }
            },
            submitting: false
        };
    },
    methods: {
        changePassword() {
            this.submitting = true;
            if(this.inputs.new_password.value !== this.inputs.confirm_password.value) {
                this.$toasted.show("Please confirm your new password.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            this.submitting = false;
                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.submitting = false;
                    }
                });

                return false;
            }

            this.$http.put("users/password", {
                password: this.inputs.password.value,
                new_password: this.inputs.new_password.value
            }).then((response) => {
                this.submitting = false;
                this.clearInputs();

                this.$toasted.success("Password updated successfully.", {
                    duration: 3000
                });
            }).catch((error) => {
                console.error(error);

                if(401 === error.response?.status) {
                    this.$toasted.error("Unable to update password. Please check if your current password is correct.", {
                        action: {
                            text: "OK",
                            onClick: (e, toast) => {
                                setTimeout(() => {
                                    this.submitting = false;
                                    $(this.$refs.password).focus();
                                });

                                toast.goAway(0);
                            }
                        },
                        onComplete: () => {
                            this.submitting = false;
                            $(this.$refs.password).focus();
                        }
                    });
                } else {
                    this.submitting = false;
                    this.$toasted.error("Unable to update password.", {
                        duration: 3000
                    });
                }
            });
        },
        togglePasswordVisibility(input) {
            input.visible = !input.visible;
        },
        removeCapsLockedClass(input) {
            input.caps_locked = false;
        },
        checkCapsLock(event, input) {
            try {
                input.caps_locked = !!event.getModifierState("CapsLock");
            } catch(error) {
            }
        },
        clearInputs() {
            this.inputs.password.value = "";
            this.inputs.new_password.value = "";
            this.inputs.confirm_password.value = "";
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.password.form {
    max-width: 480px;
    animation: animation-slideUp ease-out 300ms;
}
</style>